
.paper {
  padding: 20px 15px;
}

.header {
  height: 100%;
  padding-bottom: 5px;
  border-bottom: 1px solid #eee;
}

.headerSub {
  font-size: 0.8em;
  color: #666;
}

.textRight {
  text-align: right;
}

.textCenter {
  text-align: center;
}

.deleteButton {
  border-color: #d20101;
  color: #d20101;
}

