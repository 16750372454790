
.root {
  position: sticky;
  top: 80px;
}

.sidebar {
  max-height: calc(100vh - 160px);
  overflow-y: auto;

  /* width */
  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #B38724;
    border-radius: 4px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.subList {
  padding-left: 15px;
}

.textRight {
  text-align: right;
}

.template {
  color: #B38724;
}

.active, .active:hover {
  text-decoration: underline;
}