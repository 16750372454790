.input {
  padding-right: 5px;
  width: 100%;

  font-weight: bold;

  & > input {
    padding: 8px 15px;
    cursor: text;
  }

  & > div > button {
    padding: 5px;
  }
}

.faIcon {
  font-size: 16px;
}
