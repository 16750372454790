
.paper {
  padding: 20px 15px;
}

.error {
  text-align: center;
}

.textRight {
  text-align: right;
}

.flex {
  display: flex;
}

.flexGrow {
  flex-grow: 1;
}
