
.field {
  margin-top: 1px;
  padding-bottom: 7px;
  border-bottom: 1px solid #efefef;

  &:last-of-type {
    border-bottom: none;
  }
}

.vc {
  display: table;
  width: 100%;
  height: 100%;
}

.vcc {
  display: table-cell;
  vertical-align: middle;
}

.textField {
  width: 100%;
}

.textRight {
  text-align: right;
}

.textCenter {
  text-align: center;
}

.helpCircle {
  margin-right: 10px;
  font-size: 14px;
  color: #929292;

  &:hover {
    cursor: pointer;
    color: #c4c4c4;
  }
}